@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,regular,500,600,700,800,900,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

@mixin removeScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


@mixin noSelectText {
  user-select: none; /* Для большинства современных браузеров */
  -webkit-user-select: none; /* Для браузеров на основе WebKit (например, Safari) */
  -moz-user-select: none; /* Для Firefox */
  -ms-user-select: none; /* Для старых версий Internet Explorer */
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito";

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  
  // & {
  //   -ms-overflow-style: none;  /* IE and Edge */
  //   scrollbar-width: none;  /* Firefox */
  // }
}

$lightbloue: linear-gradient(
  150deg,
  rgba(44, 41, 79, 0.609),
  rgba(44, 41, 79, 0.2)
);
$color4: rgba(23, 23, 23, 0.69);
$color1: #2c294f;

body {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;

  
  /* width */
&::-webkit-scrollbar {
  width: 7px;
}

/* Track */
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black;
  border-radius: 20px;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: rgba(0, 184, 184, 0.712);
  border-radius: 10px;
}

&::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 184, 184, 0.85);
}
}

.AuthPage {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-5deg, rgba(44, 41, 79, 0.409), rgb(0, 0, 0));
  // background: linear-gradient(140deg, #740ba3, #3d1662, #3d1662, #740ba3);

  .auth__container {
    height: 520px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 15px;
    color: rgba(245, 245, 245, 0.872);
    z-index: 2;
    h3 {
      font-size: 2em;
    }
    backdrop-filter: blur(7px);

    input {
      background-color: rgba(255, 255, 255, 0.07);
      width: 90%;
      height: 50px;
      border-radius: 5px;
      font-size: 1.2em;
      color: whitesmoke;
      padding: 10px;
      border: rgba(255, 255, 255, 0.05) 2px solid;
      transition: 0.3s;
      &:focus {
        border: none;
        outline: 0;
        border: rgba(255, 255, 255, 0.25) 2px solid;
      }
    }

    button {
      width: 90%;
      height: 55px;
      border-radius: 5px;
      font-size: 1.2em;
      cursor: pointer;
    }
  }

  img {
    position: absolute;
    z-index: -1;
    width: 300px;
    opacity: 0;
    animation: bc 5s;

    @keyframes bc {
      50% {
        opacity: 0.5;
        transform: translate(90%, 0);
      }
    }
  }

  // animation: apr 1s 1;

  // @keyframes apr {
  //   0% {
  //     opacity: 0;
  //     transform: scale(0.9);
  //   }
  // }
}

.justInput {
  width: 200px;
  height: 35px;
  background-color: transparent;
  border: 0;
  border-bottom: 3px rgba(44, 41, 79, 0.7) solid;
  color: whitesmoke;
  font-size: 1em;
  padding-left: 5px;

  &:focus {
    outline: 0;
    border-bottom: 3px rgba(44, 41, 79, 0.9) solid;
  }
}

#search {
  background: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.279);
  color: rgba(212, 208, 208, 0.377);
  font-size: 1.15em;
  padding: 7px;
  padding-bottom: 3px;
  transition: 0.3s;
  text-align: center;
  width: 125px;
  &:focus {
    transform: scale(1.1);
    width: 170px;
    outline: none;
    border-bottom: 2px solid rgb(196, 192, 192);
    color: rgb(212, 208, 208);
  }
}

.container {
  width: 1300px;
  min-height: 100vh;
  // background: linear-gradient(173deg, $color4, rgba(29, 29, 29, 0.17));
  border-radius: 14px;
  padding: 45px;
  display: flex;
  color: white;
  padding: 15px;
}

.statistics-page {
  width: 100%;
  color: #2c294f;

  .statistics__pie__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    .statistics__pie {
      min-width: 49%;
      height: 430px;
      background: linear-gradient(
        150deg,
        rgba(23, 22, 22, 0.747),
        rgba(23, 22, 22, 0.247)
      );
      border-radius: 15px;
      padding-top: 21px;
      z-index: 1;
      border: 3px solid rgba(183, 183, 183, 0.087);
      transition: 0.3s;
      &:hover {
        transform: scale(1.005);
        filter: brightness(105%);
      }
      animation: apr 1s 1;
    }
  }

  .statistics__line__container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .statistics__line {
      width: 100%;
      height: 430px;
      background: linear-gradient(
        170deg,
        rgba(23, 22, 22, 0.547),
        rgba(23, 22, 22, 0.247)
      );
      border: 3px solid rgba(183, 183, 183, 0.087);
      border-radius: 15px;
      padding: 5px;
      padding-left: 15px;
      padding-bottom: 25px;
      padding-top: 21px;

      transition: 0.3s;
      &:hover {
        transform: scale(1.003);
        filter: brightness(105%);
      }
      animation: apr 1s 1;
    }
  }

  .timeline {
    width: 100px;
    height: 30px;
    border: 2px solid rgba(245, 245, 245, 0.144);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 0 0 auto;
    top: -103%;
    right: 10px;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 5px;
    p {
      color: whitesmoke;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 200;
    }

    &:hover {
      border: 2px solid rgba(245, 245, 245, 0.344);
    }
  }

  .statistics-page__row {
    margin-top: 20px;
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .short-recomdendtions__container {
      height: 100%;
      min-width: 49%;
      background: linear-gradient(
        170deg,
        rgba(23, 22, 22, 0.547),
        rgba(23, 22, 22, 0.247)
      );
      border: 3px solid rgba(183, 183, 183, 0.087);

      border-radius: 15px;
      padding: 12px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .short-recomendation__item {
      background: linear-gradient(
        170deg,
        rgba(237, 112, 96, 0.122),
        rgba(21, 15, 15, 0.2)
      );
      border: 2px solid rgba(100, 99, 99, 0.161);
      // border-right: 2px solid rgba(255, 115, 0, 0.93);

      margin-top: 5px;
      color: rgba(245, 245, 245, 0.849);
      padding: 7px 9px;
      border-radius: 7px;

      display: flex;
      position: relative;
      justify-content: space-between;
      strong {
        font-size: 1.05em;
        font-weight: 500;
      }

      p {
        font-size: 0.9em;
      }

      img {
        height: 20px;
        filter: brightness(80%);
      }

      .recomendation-email {
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.8);
        border: 1.5px solid rgba(183, 183, 183, 0.097);
        backdrop-filter: blur(3px);
        padding: 7px;
        border-radius: 5px;
        position: absolute;
        right: 30px;
        bottom: 10px;
      }

      .review-text{
        max-width: 80%;
        z-index: 10;
        position: absolute;
        padding: 8px;
        right: 15px;
        top: -20px;
        font-size: 1.2em;
        background: rgb(22, 22, 22);
        border-radius: 5px;
        display: none;
        border: 1px solid rgb(32, 32, 32);
        backdrop-filter: blur(5px);
      }

      

      &:hover {
        filter: brightness(120%) contrast(110%);

        .review-text{
          display: block;
        }

        .recomendation-email {
          visibility: visible;
        }
      }
    }
  }

  .statistics__boxes__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    // border: 1px solid rgba(0, 255, 0, 0.362);
    margin-top: 10px;
    padding: 0 5px;

    .box-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      margin-left: 8px;
      width: 80px;
      height: 70px;
      position: relative;
      cursor: pointer;
      user-select: none;
      background: linear-gradient(
        170deg,
        rgba(23, 22, 22, 0.547),
        rgba(23, 22, 22, 0.247)
      );
      border: 2.5px solid rgba(183, 183, 183, 0.087);
      border-radius: 8px;
      transition: 0.3s;

      img {
        width: 55%;
      }

      .numberOf {
        color: rgba(255, 255, 255, 0.694);
        font-size: 1.3em;
      }

      &:hover {
        .stat {
          visibility: visible;
        }
        transform: scale(1.02);
      }
    }

    .stat {
      position: absolute;
      padding: 2px 5px;
      background: rgba(0, 0, 0, 0.475);
      right: -17px;
      top: -5px;
      border-radius: 3px;
      border: 1.5px solid rgba(183, 183, 183, 0.087);
      visibility: hidden;

      p {
        color: rgba(245, 245, 245, 0.864);
        font-size: 1.1em;
        font-weight: 300;
      }
    }
  }
}

.plan-page {
  width: 100%;

  .plan__item {
    width: 100%;
    height: 70px;
    border: 3px solid rgba(97, 97, 97, 0.17);
    margin: 7px 0;
    border-radius: 7px;
    background: linear-gradient(
      150deg,
      rgba(44, 41, 79, 0.609),
      rgba(44, 41, 79, 0.2)
    );
    animation: appear 0.8s 1;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 3px;
    filter: opacity(0.87);
    padding: 5px 5px;

    .infrom {
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 30%;
        height: 100%;
        margin-right: 15%;
      }

      .key {
        height: 25px;
      }
    }
    img {
      height: 37px;
      cursor: pointer;
      transition: 0.3s;
      filter: brightness(70%);
      &:hover {
        transform: scale(1.07);
        filter: brightness(80%);
      }
    }
    .timeline {
      width: 50%;
      height: 5.5px;
      background: linear-gradient(
        90deg,
        rgba(0, 255, 0, 0.508),
        rgba(0, 255, 0, 0.408)
      );
      border-radius: 7px;
      box-shadow: #49a09d 0 0px 5px 0.77px;
      filter: blur(0.52px);
    }
    transition: 0.3s;

    &:hover {
      filter: opacity(1);
    }
  }
}

.payment-page {
  width: 100%;

  .payments-header {
    display: flex;
    justify-content: space-between;

    img {
      margin-right: 10px;
      height: 25px;
      cursor: pointer;
      transition: 0.3s;
      opacity: 0.8;
      transform: rotate(0deg);

      &:hover {
        transform: rotate(20deg);
      }
    }
  }
}

.transactions-page {
  width: 100%;

  .transaction-item {
    width: 100%;
    min-height: 70px;
    padding: 15px;
    border: 3px solid rgba(97, 97, 97, 0.17);
    margin: 7px 0;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      150deg,
      rgba(44, 41, 79, 0.609),
      rgba(44, 41, 79, 0.2)
    );
    transition: 0.37s;
    animation: appear 0.8s 1;
    flex-wrap: wrap;

    span {
      width: 30%;
    }

    .email {
      width: 170px;
      overflow: scroll;
      @include removeScrollBar();
    }

    .time {
      width: 70px;
    }

    .options {
      width: 120px;
      display: flex;
      justify-content: space-between;

      p {
        cursor: pointer;
        font-weight: 500;
        transition: 0.35s;
        border: 1.5px solid rgba(173, 216, 230, 0.22);
        padding: 3.5px;
        border-radius: 3px;

        &:hover {
          transform: scale(1.1);
          display: inline-block;
        }

        &:active {
          transform: scale(1.05);
        }
      }

      .ac {
        color: rgba(102, 255, 102, 0.686);
      }
    }
  }
}

.new-day__block {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    150deg,
    rgba(0, 235, 0, 0.316),
    rgba(0, 235, 0, 0.13)
  );
  filter: opacity(0.87);
  border: 2.5px solid rgba(0, 235, 0, 0.07);
  border-radius: 5px;
  font-size: 1.2em;

  h3 {
    font-weight: 400;
  }

  animation: appear 0.8s 1;
}

.payment__item {
  width: 100%;
  min-height: 70px;
  padding: 15px;
  border: 3px solid rgba(97, 97, 97, 0.17);
  margin: 7px 0;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    150deg,
    rgba(44, 41, 79, 0.609),
    rgba(44, 41, 79, 0.2)
  );
  transition: 0.37s;
  cursor: pointer;
  animation: appear 0.8s 1;

  p {
    font-family: "Nunito";
    font-size: 1.2em;
    font-weight: 300;
    width: 300px;
  }

  span {
    font-size: 0.77em;
    margin-left: 5px;
    color: white;
  }

  h4 {
    font-size: 1.25em;
    font-weight: 350;
    font-family: "Nunito";
  }

  .free-game {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    color: rgb(245, 179, 179);
  }

  .payement-cashback{
    color: rgb(228, 173, 70);
    font-size: 0.7em;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
    font-weight: 800;
    margin-right: 8px;
  }
  

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(44, 41, 79, 0.7),
      rgba(44, 41, 79, 0.5)
    );

    // border: 4px solid rgba(97, 97, 97, 0.2);
  }
}

.users-page__header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 7px;

  img {
    height: 27px;
    width: 27px;
    cursor: pointer;
    filter: brightness(80%);
    transition: 0.3s;

    &:hover {
      filter: brightness(100%);
      transform: scale(1.05);
    }
  }

  .userIcon {
    height: 27px;
  }

  h4 {
    font-size: 27px;
    font-weight: 300;
    margin-right: 5px;
    color: rgb(212, 208, 208);
  }

  h5 {
    font-weight: 500;
    font-style: italic;
    transform: translateY(-1px);
  }
}

.games-page {
  width: 100%;

  .games-page__header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .game-item__price{
    position: relative;
    margin: 7px 0;
    width: 100%;
    min-height: 39px;
    background: linear-gradient(
      150deg,
      rgba(44, 41, 79, 0.609),
      rgba(44, 41, 79, 0.2)
    );
    border-radius: 5px;
    padding: 5px 15px;
    border: 3px solid rgba(97, 97, 97, 0.17);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    p {
      margin: 5px;
      font-weight: 300;
    }
    span {
      margin-left: 5px;
      cursor: pointer;
      font-weight: 500;
    }

    strong {
      cursor: pointer;
    }
  }

  .game-item__auth {
    position: relative;
    width: 100%;
    min-height: 39px;
    background: linear-gradient(
      150deg,
      rgba(44, 41, 79, 0.609),
      rgba(44, 41, 79, 0.2)
    );
    border-radius: 8px;
    padding: 5px 15px;
    border: 3px solid rgba(97, 97, 97, 0.17);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    p {
      margin: 5px;
      font-weight: 300;
    }
    span {
      margin-left: 5px;
      cursor: pointer;
      font-weight: 500;
    }

    strong {
      cursor: pointer;
    }
    input {
      margin: 5px;
      background-color: transparent;
      border: none;
      border-bottom: #dedede38 1px solid;
      font-size: 1em;
      color: white;
      padding: 2px 5px;

      

      &:focus {
        transition: 0.21s;
        outline: none;
        border-bottom: 1px solid rgb(201, 201, 201);
      }
    }
  }
}

.btn {
  width: 100px;
  height: 40px;
  color: whitesmoke;
  cursor: pointer;
  // background: linear-gradient(
  //   130deg,
  //   rgba(75, 21, 93, 0.5),
  //   rgba(44, 41, 79, 0.5)
  // );
  background: $lightbloue;
  border: 2px solid rgba(97, 97, 97, 0.1);
  border-radius: 5px;
  transition: 0.15s;
  &:hover {
    filter: brightness(110%);
    border: 2.5px solid rgba(97, 97, 97, 0.03);
  }
}

.game__item {
  position: relative;
  width: 100%;
  height: 70px;
  padding: 10px 15px;
  border: 3px solid rgba(97, 97, 97, 0.17);
  margin: 7px 0;
  border-radius: 7px;
  overflow: hidden;
  background: linear-gradient(
    150deg,
    rgba(44, 41, 79, 0.609),
    rgba(44, 41, 79, 0.2)
  );
  transition: 0.37s;
  animation: appear 0.8s 1;
  

  .more {
    filter: hue-rotate(190deg);
  }

  .top {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      cursor: pointer;
    }

    span {
      font-weight: 700;
      font-size: 1.3em;
      color: rgba(255, 255, 255, 0.7);
      transition: 0.5s;
    }

    img {
      height: 50px;
      cursor: pointer;
      transition: 0.3s;
      opacity: 0.9;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .bottom {
    opacity: 0;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 1.2s;

    .bottom1{
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img{
        margin-right: 21px;
        cursor: pointer;
        filter: brightness(80%);

        transition: 0.3s;
        &:hover{
          filter: brightness(100%);
          transform: scale(1.07) rotate(-10deg);
        }
      }
    }

    .gameVisibilityIcon {
      width: 25px;
      height: 25px;
      transition: 0.3s;
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;

      min-width: 20%;

      p {
        cursor: pointer;
      }

      img {
        cursor: pointer;
        transition: 0.3s;
        filter: brightness(8 0%);
        &:hover {
          filter: brightness(100%);
          transform: rotate(3.7deg) scale(1.07);
        }
      }

      .gameVisibilityIcon {
        width: 25px;
        height: 25px;
        transition: 0.3s;
      }
    }
  }
  

  .game__item__part {
    width: 350px;
    display: flex;
    justify-content: space-between;
    p {
      transition: 0.7s;
      cursor: pointer;
    }
  }

  .game__item__part_sm {
    width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.7s;
  }

  .open_btn {
    transform: rotate(90deg);
    cursor: pointer;
    margin-left: 15px;
  }

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(44, 41, 79, 0.7),
      rgba(44, 41, 79, 0.5)
    );
  }
}

.price {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 20%;

  p {
    cursor: pointer;
  }

  img {
    cursor: pointer;
    transition: 0.3s;
    filter: brightness(8 0%);
    &:hover {
      filter: brightness(100%);
      transform: rotate(3.7deg) scale(1.07);
    }
  }

  .gameVisibilityIcon {
    width: 25px;
    height: 25px;
    transition: 0.3s;
  }
}

.game__item_free{
  background: linear-gradient(
    150deg,
    rgba(44, 41, 79, 0.609),
    rgba(44, 41, 79, 0.2)
  );
  background: linear-gradient(
    150deg,
    rgba(41, 79, 70, 0.637),
    rgba(44, 41, 79, 0.2)
  );

  background: linear-gradient(
    170deg,
    rgba(79, 41, 41, 0.637),
    rgba(44, 41, 79, 0.2)
  );

  // background: linear-gradient(
  //   150deg,
  //   rgba(15, 175, 138, 0.637),
  //   rgba(40, 94, 86, 0.2)
  // );
  border: 3px solid rgba(46, 46, 46, 0.17);
  &:hover{
    background: linear-gradient(
    170deg,
    rgba(79, 41, 41, 0.637),
    rgba(44, 41, 79, 0.3)
  );
  }
}


.game__item_discount{
  background: linear-gradient(
    150deg,
    rgba(44, 41, 79, 0.609),
    rgba(21, 69, 92, 0.23)
  );
}

.game-item__discountbox{
  display: inline-flex;
  padding: 5px;
  font-size: 0.7em;
  margin-left: 10px;
  // border: 2px solid rgba(255, 255, 255, 0.301);
  box-shadow: 0 0 3px 0 rgb(192, 134, 25);
  border-radius: 5px;
  background-color: rgb(161, 114, 26) ;
  color: black;
  font-weight: 800;

  transition: 0.3s;
  &:hover{
    background-color: rgb(187, 131, 26) ;
    box-shadow: 0 0 5px 0 rgb(236, 163, 27);
  }
}

.free-count{
  margin-left: 5px;
  // color: rgb(255, 103, 128); 
  // color: rgba(71, 241, 55, 0.788); 
  font-weight: 800;
  font-family: 'Nunito';
  font-size: 0.95em;

  
}

#gamefn-icon{
  position: absolute;
  left: 10px;
}

.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game_item_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px;
}

#open_p {
  .top {
    margin-bottom: 25px;
  }
  &:hover {
    background: linear-gradient(
      120deg,
      rgba(44, 41, 79, 0.609),
      rgba(44, 41, 79, 0.2)
    );
  }
}
.mini-payment {
  width: 100%;
  border-radius: 5px;
  padding: 2.5px;
  margin-top: 3px;
  border: 2px solid rgba(205, 205, 205, 0.037);
  display: flex;
  justify-content: space-around;
  color: rgba(218, 218, 218, 0.582);
  font-size: 0.85em;
  padding: 2.5px;
  background: linear-gradient(
    150deg,
    rgba(44, 41, 79, 0.409),
    rgba(44, 41, 79, 0.1)
  );
  transition: 0.37s;

  &:hover {
    color: rgba(255, 255, 255, 0.782);
    filter: brightness(120%);
  }
}

.user__item {
  width: 100%;
  min-height: 70px;
  padding: 15px;
  border: 3px solid rgba(97, 97, 97, 0.17);
  margin: 7px 0;
  border-radius: 7px;

  background: linear-gradient(
    150deg,
    rgba(44, 41, 79, 0.609),
    rgba(44, 41, 79, 0.2)
  );
  transition: 0.37s;
  animation: appear 0.8s 1;

  p {
    font-family: "Nunito";
    font-size: 1.2em;
    font-weight: 300;
    width: 25%;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
  }

  .fullmail {
    width: auto;
    position: absolute;
    padding: 3px 7.5px;
    margin-bottom: 30px;
    margin-left: 20px;
    background-color: rgba(58, 56, 82, 0.95);
    border: 2px solid rgba(70, 68, 86, 0.35);
    border-radius: 5px;
    display: none;
    transition: 0.7s;
    filter: opacity(0);
    &:hover {
      filter: opacity(1);
      transform: scale(1.05);
      display: block;
    }
  }

  .email {
    width: 30%;
    &:hover ~ .fullmail {
      display: block;
    }
  }

  h4 {
    font-size: 1.25em;
    font-weight: 350;
    font-family: "Nunito";
  }

  img {
    filter: brightness(80%);
    transition: 0.37s;
    cursor: pointer;
    &:hover {
      filter: brightness(100%);
      transform: scale(1.07);
    }
  }

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(44, 41, 79, 0.7),
      rgba(44, 41, 79, 0.5)
    );
    transform: scale(1.007);
  }
}

.guards-page {
  width: 100%;
  min-height: 100vh;

  .guards-page__header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;

    .upd-btn {
      width: 100px;
      height: 40px;
      background: $lightbloue;
      border: 2px solid rgba(97, 97, 97, 0.1);
      border-radius: 5px;
      transition: 0.15s;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        filter: brightness(110%);
        border: 2.5px solid rgba(97, 97, 97, 0.03);
      }
    }
  }

  .guard__item {
    width: 100%;
    height: 70px;
    padding: 10px 15px;
    border: 3px solid rgba(97, 97, 97, 0.17);
    margin: 7px 0;
    border-radius: 7px;
    background: linear-gradient(
      150deg,
      rgba(44, 41, 79, 0.609),
      rgba(44, 41, 79, 0.2)
    );
    transition: 0.37s;
    animation: appear 0.8s 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 1.1em;

    .support {
      color: red;
    }

    .block {
      min-width: 45%;
      display: flex;
      justify-content: space-between;
      // flex-direction: column;
    }

    span {
      font-weight: 700;
      font-size: 1.15em;
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      transform: scale(1.007);
    }

    .ep {
      color: rgb(0, 232, 170);
      font-weight: 400;
    }
  }
}

#copyAnim {
  animation: 1 coppy 0.78s;
}

#shake {
  animation: 3 shake 0.78s;
}
@keyframes coppy {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

///////////////
/////////////////CheckBix////////
.checkbox-wrapper-3 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-3 .toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-3 .toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #9a9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}
.checkbox-wrapper-3 .toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}
.checkbox-wrapper-3 .toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(79, 46, 220, 0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.checkbox-wrapper-3 #cbx-3:checked + .toggle:before {
  background: #947ada;
}
.checkbox-wrapper-3 #cbx-3:checked + .toggle span {
  background: #4f2edc;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}
.checkbox-wrapper-3 #cbx-3:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.modal {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s linear;
  z-index: -1;

  p {
    margin-left: 7px;
  }

  .modal-content {
    width: 400px;
    min-height: 300px;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 17px;
    border-radius: 10px;
    position: fixed;
    background: linear-gradient(
      150deg,
      rgba(38, 34, 74, 0.751),
      rgba(31, 29, 54, 0.2)
    );
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid rgba(44, 41, 79, 0.309);

    .modal-content-status-wrapper{
      width: 100%;
      display: flex;
      justify-content: space-around;
      // padding: 10px 0;
      // padding-top: 5px;
      margin-top: 10px;
      flex-wrap: wrap;
      
      .modal-content-status-choice{
          padding: 5px 15px;
          width: 115px;
          text-align: center;
          border: 2px solid teal;
          border-radius: 5px;
          cursor: pointer;
          color: whitesmoke;
      }

      #modal-content-status-choice-selected{
        background-color: rgba(0, 128, 128, 0.349);
      }

    }

    #screens-box{
      background-color: rgba(0, 0, 0, 0.520);
      padding: 5px 10px;
      border-radius: 8px;
    }

    .input-container{
      position: relative;
      margin: 10px 0;
    }

    input {
      width: 100%;
      height: 37px;
      background-color: rgba(44, 41, 79, 0.594);
      border: 0;
      // border-bottom: 1px rgba(20, 255, 235, 0.211) solid;
      border-radius: 7px;
      color: white;
      font-size: 1em;
      padding-left: 5px;
      transition: 0.05s;

      &:focus {
        outline: 0;
        // border-bottom: 2px #14ffebc2 solid;
      }

      &:not(:placeholder-shown){
        border-bottom: 3px rgb(0, 167, 167) solid;
        // box-shadow: 0 0 5px 0 rgb(0, 167, 167);

      }

    }

    .modal-content-screens-wrapper{


      #input-container-screen{
        position: relative;
        display: flex;
        align-items: center;
        .add-screen-btn{
          cursor: pointer;
          position: absolute;
          right: 10px;
          // top: 10px;
          color: rgb(255, 255, 255);
          font-size: 1.5em;
          font-weight: 300;
          margin-bottom: 7px;
        }
      }

     
      
      .screens-modal{
        background-color: rgba(39, 39, 39, 0.801);
        overflow: hidden;
        border: 2px solid blue;

        border: rgba(56, 56, 56, 0.826) 1px solid;
        border-radius: 5px;
        font-size: 0.8em;
        position: absolute;
        right: -10px;
        top: 5px;
        display: none;
        z-index: 3;

        &:hover{
          display: block;
        }
        .screens-modal-option{
          color: white;
          text-align: center;
          padding: 5px 25px;
          cursor: pointer;

          &:hover{
            background-color: rgb(49, 49, 49);
          }
        }
      }

      .add-screen-btn:hover ~.screens-modal{
        display: block;
      }
      
    }
    .modal-content-rating-wrapper{
      display: flex;
      justify-content: end;
      #input-container-short{
      width: 50%;
    }
    }

    .modal-auto{
      display: flex;
      position: absolute;
      bottom: 25px;
      left: 38px;
      p{
        margin-left:5px;
      }
    }


    

  
    
    
    color: rgba(255, 255, 255, 0.523);
    textarea {
      width: 100%;
      height: 100px;
      background-color: rgba(44, 41, 79, 0.594);
      border: 0;
      color: white;
      border-bottom: 3px rgba(20, 255, 235, 0.361) solid;
      border-radius: 7px;
      font-size: 1em;
      padding-left: 5px;
      margin-bottom: 15px;

      &:focus {
        outline: 0;
        border-bottom: 3px #14ffebc2 solid;
      }
    }

    .prices {
      display: flex;
      justify-content: space-around;
      input {
        width: 75px;
      }
    }

    button {
      margin-top: 25px;
      width: 75px;
      height: 37px;
      position: relative;
      left: calc(50% - 39px);
      background-image: linear-gradient(
        to right,
        #5f2c82 0%,
        #49a09d 51%,
        #5f2c82 100%
      );
      cursor: pointer;
      text-align: center;
      transition: 0.5s;
      background-size: 200% auto;
      color: white;
      border-radius: 7px;
      border: solid 3px rgba(95, 44, 130, 0.1);

      &:hover {
        background-position: right center;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

#open {
  height: 120px;

  .bottom {
    opacity: 1;
  }
}

#hidden {
  opacity: 0.2;
  p {
    opacity: 0;
  }
}

///
///
///
///
///
///
///
///
///
///
///
///
///
/// .
///
.onlineGame {
  border-left: rgb(0, 157, 157) 3px solid;
  border-left: rgb(11, 182, 125) 3px solid;
}

.gameAccount {
  // border-left: rgba(216, 11, 11, 0.894) 3px solid;

  background: linear-gradient(
    150deg,
    rgba(79, 41, 71, 0.637),
    rgba(79, 41, 41, 0.637)
  );

  &:hover{
    transition: 0.3s;
    background: linear-gradient(
      150deg,
      rgba(79, 41, 71, 0.737),
      rgba(79, 41, 41, 0.737)
    );
  }
}

#codereceived {
  border-right: 3px solid #fbbb437a;
  // border-right: 3px solid #49fb43ba;
  // border-right: 3px solid #fb43d9ba;
}

#TopUp{
  background: linear-gradient(
    150deg,
    rgba(41, 79, 70, 0.637),
    rgba(41, 61, 79, 0.637)
  );
}



///
///
///
///

.nothing {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 75px;
    height: 75px;
  }
}

.notifications {
  --size: 21px;
  min-width: var(--size);
  height: var(--size);
  background-color: rgba(255, 46, 46, 0.676);
  // border: 1px solid rgba(255, 255, 255, 0.09);
  box-shadow: 0 0 8px 0px rgba(255, 46, 46, 0.676);
  padding: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  margin-right: 10px;
}

#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}
.menu__btn {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 5;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background: linear-gradient(173deg, $color4, rgba(29, 29, 29, 0.17));
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
  z-index: 3;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: whitesmoke;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.45s;
  z-index: 99999999;

  a {
    z-index: 1000;
  }
}
.menu__item:hover {
  text-decoration: underline;
  transform: scale(1.05);
}
@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes apr {
  0% {
    opacity: 0;
    // filter: blur(8px);
  }
  100% {
    // filter: blur(0);
    opacity: 1;
  }
}

@media (max-width: 1500px) {
  .container {
    width: 85vw;
  }
}

@media (max-width: 1150px) {
  .container {
    width: 95vw;
  }

  .payment__item {
    height: 90px;
    flex-wrap: wrap;
  }

  .game__item {
    width: 100%;
  }
}
@media (min-width: 800px) {
  .statistics__pie {
    width: 49%;
  }

  .auth__container {
    width: 420px;
  }
  .short-recomdendtions__container {
    width: 48%;
  }
}


@media (max-width: 500px) {
  #gamefn-icon{
    display: none;
  }
  .game-item__price{
   padding: 10px 0 !important;
    .price{
      width: 25%;
      
    } 
  }
}

@media (max-width: 800px) {
  .statistics__boxes__container {
    margin-bottom: -15px;
    .box-item {
      margin-bottom: 0px;
      margin-left: 0px;
      width: 80px;
      height: 70px;
      display: none;
    }
  }
  .guards-page {
    .guard__item {
      .block {
        flex-direction: column;
        p {
          font-size: 0.9em;
          margin-top: 3px;
        }
      }
    }
  }
  .container {
    width: 100vw;
  }
  .payment__item {
    height: 140px;
  }

  .game__item {
    padding: 5px;
    p {
      font-size: 0.8em;
    }

    span {
      font-size: 0.82em;
    }

    h4 {
      font-size: 0.8em;
    }

    img {
      transform: scale(0.8);
    }

    .bottom {
      .price {
        width: 25%;
        p {
          margin-left: 7px;
        }

        .gameVisibilityIcon {
          display: none;
        }
      }
    }

    .game__item__part {
      width: 150px;
    }

    .game__item__part_sm {
      width: 50px;
    }
  }
  .auth__container {
    width: 90%;
    img {
      display: none;
    }
  }
  .statistics__pie__container {
    flex-direction: column;
    align-items: center;

    .statistics__pie {
      width: 100%;
      margin-top: 25px;
    }
  }

  .users-page__header {
    align-items: end;
    img {
      width: 21px;
      height: 21px;
    }

    h4 {
      font-size: 20px;
    }
  }

  .statistics-page__row {
    flex-direction: column;
    .short-recomdendtions__container {
      width: 100%;
      margin-top: 20px;
      background-color: transparent;
      .short-recomendation__item {
        width: 100%;
      }
    }
  }

  #search {
    &:focus {
      width: 125px;
      transform: scale(1);
    }
  }
}

.reg-user {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif !important;

  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: rgb(214, 184, 184);
  text-underline-offset: 2.5px;
  color: rgb(255, 234, 234);
  text-transform: uppercase;
  font-size: 1.05em !important;

  // text-decoration-color: rgb(0, 207, 207);
  
  font-weight: 700;
}

.isUserReg{
  // color: rgb(2, 228, 152) !important;
  // color: rgb(255, 68, 0) !important; 
  color: rgb(255, 103, 128) !important; 
  font-weight: 800;
  font-family: 'Nunito'
}




.linkstat-page{
  width: 100%;
  min-height: 100vh;
}

.link-stat-item{
  width: 100%;
  min-height: 70px;
  padding: 15px;
  border: 3px solid rgba(97, 97, 97, 0.17);
  margin: 7px 0;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    150deg,
    rgba(44, 41, 79, 0.609),
    rgba(44, 41, 79, 0.2)
  );
  transition: 0.37s;
  cursor: pointer;
  animation: appear 0.8s 1;
  color: white;
  font-size: 1em;
  
  .new-clicks{
    color: greenyellow;
    font-weight: 700;
  }

  .stat-item__name{
    width: 100px;
      font-weight: 700;
      font-size: 1.1em;
      text-transform: uppercase;

      
  }
  
  .stat-item__sales{
    position: relative;

    .stat-item__payments{
      width: 250px;
      // min-height: 150px;
      border: 2.5px solid rgba(183, 183, 183, 0.087);
      border-radius: 8px;
      position: absolute;
      backdrop-filter: blur(3px);
      font-size: 0.75em;
      // display: none;
      z-index: 5;
      border: 2px solid rgba(58, 58, 58, 0.205);
      background-color: rgb(20, 20, 20);
      display: none;
      animation: anim1 0.3s 1;

      .stat-item__payments-item{
        width: 100%;
        padding: 8px 8px ;
        border-bottom: 2px solid rgba(255, 255, 255, 0.137);
        font-size: 1.2em;

        p{
          width: 100%;
        }

        .item-price{
          text-align: end;
        }
    
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
  .stat-item__sales:hover .stat-item__payments{
    display: block;
  }

  .stat-item__clicks{
    display: flex;
    align-items: center;
    
      img{
        height: 20px;
      }
   }

   .stat-item__money{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
      img{
        margin-left: 5px;
        height: 30px;
        filter: brightness(80%);
      }
   }

   p{
    width: 135px;
   }
  

}




@media (max-width:900px) {
  .link-stat-item{
  flex-wrap: wrap;

  P{
    margin-top: 5px;
  }
 
  }
}





// $color5:rgba(0, 128, 128, 0.658);
$color5:rgba(255, 255, 255, 0.137);
$color3:rgba(255, 192, 203, 0.534);


.payment-item__userpyaments{
  border: 2.5px solid rgba(183, 183, 183, 0.087);
  border-radius: 8px;
  position: absolute;
  backdrop-filter: blur(3px);
  font-size: 0.75em;
  display: none;
  z-index: 5;
  color: white;
  // box-shadow: 0 0 3px 2px $color5;
  border: 2px solid $color5;
  background-color: rgb(20, 20, 20);
  overflow: hidden;


  .payment-item__userpyaments-item{
    padding: 3px 5px ;
    border-bottom: 2px solid rgba(255, 255, 255, 0.137);

    &:last-child{
      border-bottom: none;
    }
  }

  .datee{
    text-align: end;
    font-weight: 500;
  }
}

.payment-item__userpyaments_inf{
  max-width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  span{
    font-size: 1.1em;
    display: inline-flex;
    padding: 5px 10px;
    background:linear-gradient(90deg,rgb(95, 4, 19),purple);
    background:linear-gradient(90deg,#301934,#483248);
    border-radius: 5px;
  }
}

.payment-item__reg-user_inf{
  max-width: 100%;
  height: 32px;
  padding-top: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // background: linear-gradient(
  //   150deg,
  //   rgba(41, 79, 70, 0.537),
  //   rgba(41, 61, 79, 0.537)
  // );
  // background: linear-gradient(
  //   150deg,
  //   rgba(214, 148, 26,0.8),
  //   rgba(161, 114, 26, 0.925)
  // );
  overflow: hidden;
  // box-shadow: 0 0 1px 2px rgba(41, 61, 79, 0.637) ;
  font-style: italic;
  color: rgb(218, 179, 107);


  // color: rgb(228, 173, 70);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
  "Lucida Sans", Arial, sans-serif;


  ///
  /// 
  font-weight: 700 !important;
  font-size: 1.1em;

}

#payment-item__user-email:hover .payment-item__userpyaments{
 display: block;
 animation: anim1 0.3s 1
}


@keyframes anim1 {
  0%{
    opacity: 0;
    transform: translateX(-5px)
  }
}











/* HTML: <div class="loader"></div> */
.loader2 {
  display: block;
  height: 50px;
  aspect-ratio: 2;
  border: 10px solid #000;
  box-sizing: border-box;
  background: 
    radial-gradient(farthest-side,#fff 98%,#0000) left/20px 20px,
    radial-gradient(farthest-side,#fff 98%,#0000) left/20px 20px,
    radial-gradient(farthest-side,#fff 98%,#0000) center/20px 20px,
    radial-gradient(farthest-side,#fff 98%,#0000) right/20px 20px,
    #000;
  background-repeat: no-repeat;
  filter: blur(4px) contrast(10);
  animation: l14 1s infinite;
}
@keyframes l14 {
  100%  {background-position:right,left,center,right}
}

.loader2-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}








#link-notif{
  background: rgba(255, 0, 0, 0.349);
  border: 1px solid rgba(255, 255, 255, 0.185);
  background: rgba(0, 128, 128, 0.623);
  box-shadow: 0 0 8px 0px teal;
  position: relative;
  cursor: pointer;


  .link-notif-sum{
    position: absolute;
    left: 30px;
    border: 1px solid rgba(255, 255, 255, 0.479);
    box-shadow: 0 0 3px 1px rgb(122, 122, 122);
    // padding: 5px;
    display: flex;
    border-radius: 5px;
    background: black;
    display: none;

    .link-notif-sum-item{
      color: white;
      padding: 5px;
    }
  }

  &:hover .link-notif-sum{
    display: flex;
  }
}
















.ggstat-page{
  width: 100%;
  min-height: 100vh;
  // border: 1px solid rgb(53, 53, 53);

  #ggstat-page__day-header-setAdd{
    background: linear-gradient(150deg,#11998e9c,#31ce6d85,#e62d2d9d);

  }

  .gg-header{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    .gg-header__btn{
      padding: 5px 8px;
      background: rgba(44,41,79,.609);
      border-radius: 3.5px;
      border: 1px solid rgba(255, 255, 255, 0.068);
      cursor: pointer;
      @include noSelectText;
    }
  }


  .ggstat-page__day{
    width: 100%;


    .ggstat-page__day-header{
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(150deg,#11998e9c,#31ce6d85,#38ef7e34);
      padding: 5px;
      animation: appear 0.5s 1;
      transition: 0.3s;
      filter: opacity(0.8);

      
      margin: 5px 0; 
      border-radius: 3.5px;
      box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.089);

      .header-day__net-income{
        width: 55px;
      }

      .day-header-input{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid white;
        width: 50px;
        color: white;
        min-height: 1em;
        &:focus{
          outline: none;
        }
      }


    .header-input-wrapper{
      display: flex;
    }

    .header-input-btn{
      margin-right: 10px;
      cursor: pointer;
      
      animation: left-anim 0.5s 1;
      @keyframes left-anim {
        0%{
          transform: translateX(-5px);
          opacity: 0;
        }

      }
    }

    .header-day{
      font-weight: 500;
      font-size: 1.2em;
      padding: 10px;
    }

    .header-day-stat{
      display: flex;
      justify-content: end;
      min-width: 150px;

      .stat-item{
        margin-left: 15px;
        // width: 250px;
  
      }
    }
    

   

    span{
      font-weight: 500;
      font-size: 1.1em;
    }
    }
  }
  

  .ggstat-page__payment{
    display: flex;
    padding: 20px;
    border-radius:  3px;
    background-color: rgb(20, 20, 20);
    background: linear-gradient(150deg,rgba(44,41,79,.609),rgba(44,41,79,.2));
    // background: linear-gradient(150deg,rgba(41, 79, 71, 0.609),rgba(41, 78, 79, 0.3));
    justify-content: space-between;
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.089);


    margin: 8px 0;

    animation: apr 0.5s 1;
  }
  

  @keyframes apr {
    0%{
      opacity: 0.3;
      transform: translateY(-5px);
    }
  }

}


#btn-active{
  background: linear-gradient(150deg,#11998e9c,#31ce6d85,#38ef7e34);
}




.gg-product-item-wrp{
  width: 100%;
  position: relative;
}



.gg-product-item{
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius:  3px;
  background-color: rgb(20, 20, 20);
  background: linear-gradient(150deg,rgba(44,41,79,.609),rgba(44,41,79,.2));
  // background: linear-gradient(150deg,rgba(41, 79, 71, 0.609),rgba(41, 78, 79, 0.3));
  justify-content: space-between;
  box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.089);
  margin: 8px 0;

  .gg-product-item__title{
    width: 700px;
  }

  &:hover{
    filter: brightness(110%);
  }


  .gg-product-item__commission{
    cursor: pointer;
  }

  .gg-product-item__auth-btn{
    filter: opacity(0.7);
    cursor: pointer;

    transition: 0.15s;
    &:hover{
      transform: rotate(8deg);
      filter: opacity(1);
    }
  }
}

.gg-product-btm{
  width: 100%;
  height: 35px;
  background: linear-gradient(150deg,rgba(44,41,79,.609),rgba(44,41,79,.2));
  box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.089);
  border-radius:  3px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  span{
    margin-right: 8px;
  }

  .btm-item{
    display: flex;
  }
  
  .btm-auth{
    display: flex;
    justify-content: space-between;
    width: 35%;
  }

  animation: apr-left 0.5s 1;

  @keyframes apr-left {
    0%{ 
      transform: translateY(-5px);
      opacity: 0;
    }
  }
}








.plain-input{
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  width: 35px;
  color: white;
  min-height: 1em;
  &:focus{
    outline: none;
  }
}


.click-input-wrp{
  // width: 100px;
  .click-input-text{
    cursor: pointer;
  }

  .click-input-btn{
    cursor: pointer;
  }

  input{
    width: 80%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.808);
    color: white;
    min-height: 1em;
    &:focus{
      outline: none;
      border-bottom: 1px solid rgb(255, 255, 255);
    }
   }
}

